html {
  font-size: 10px;
  font-family: var(--text-font-family);

  --base-text-font-size: 1rem;
  --base-title-font-size: 1rem;
}

:root {
  /* ---------- screen width ---------- */
  --mobile-base-width: calc(100% - 30px);
  --web-base-width: 90%;
  --web-base-maxwidth: 1400px;

  /* ---------- font weight ---------- */
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;

  /* ---------- static colors ---------- */
  --white-color: #ffffff;
  --white-light-color: #dddddd;
  --white-tint-color: #bbbbbb;
  --white-dark-color: #aaaaaa;

  --black-color: #000000;
  --black-light-color: #666666;
  --black-tint-color: #444444;
  --black-dark-color: #222222;

  --gray-bgcolor: #f1f1f1;
  --gray-light-bgcolor: #f9f9f9;
  --gray-tint-bgcolor: #f4f4f4;

  --box-shadow-color: 1px 1px 14px 1px #0000000d;
}
